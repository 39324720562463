<template>
  <div class="brand">
    <div class="brand_center">
      <!-- <h1>党建品牌</h1> -->
      <header>
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{routerName}}</el-breadcrumb-item>
          <el-breadcrumb-item v-if="nowtitle!==null">{{nowtitle}}</el-breadcrumb-item>
        </el-breadcrumb>
      </header>
      <main>
        <div class="brand_left">
          <div class="list_one">
            <p>党建品牌</p>
          </div>
          <div class="list_two" :class="nowId==1?'blue':'white'" @click="cutnum(1,'支部党建新闻')">
            <p>支部党建新闻</p>
          </div>
          <div class="list_three" :class="nowId==2?'blue':'white'" @click="cutnum(2,'学区工作宣传')">
            <p>学区工作宣传</p>
          </div>
        </div>
        <div class="brand_right">
          <p class="r_title">{{nowtitle}}</p>
          <div class="r_list">
            <div class="liss" v-for="item in nowlistdata" :key="item.id">
              <p class="li_left" @click="gotopath(item)">{{item.title}}</p>
              <p class="li_right">{{item.time}}</p>
            </div>
          </div>
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="curpage"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="pagesize"
            layout="total, prev, pager, next, sizes"
            :total="total"
          ></el-pagination>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 面包屑
      routerName: null,
      // routerNames: null,
      // 左侧导航
      nowId: 1,
      // 右侧列表
      nowtitle: null,
      nowlistdata: null,
      // 分页
      curpage: 1,
      pagesize: 10,
      total: null,
    };
  },
  created() {
    console.log(this.$route.meta.title, "路由");
    this.routerName = this.$route.meta.title;
    if (this.$route.query.text == "支部党建新闻") {
      this.cutnum(1, "支部党建新闻");
      this.querylist(1);
    } else if (this.$route.query.text == "学区工作宣传") {
      this.cutnum(2, "学区工作宣传");
      this.querylist(2);
    } else {
      this.cutnum(1, "支部党建新闻");
      this.querylist(1);
    }
  },
  methods: {
    //切换导航
    cutnum(num, text) {
      this.nowId = num;
      this.nowtitle = text;
      this.querylist(num);
    },
    // 获取列表数据
    querylist(num) {
      if (num == 1) {
        let aa = [
          {
            id: 1,
            title: "朝阳区教科院举办新学期师德专题教育大会",
            time: "2022-08-04",
          },
          {
            id: 2,
            title:
              "发挥基层党组织战斗堡垒作用 汇聚朝阳高中教育发展新动能——区教科院高中教研部党支部召开组织生活会",
            time: "2022-08-04 ",
          },
          {
            id: 3,
            title: "五四青年节学习感悟报道（二）",
            time: "2022-08-04",
          },
          {
            id: 4,
            title: "扎实开展党史学习教育 发挥铸魂育人功能",
            time: "2022-08-04",
          },
          // {
          //   id: 5,
          //   title:
          //     "基础教育国家级优秀教学成果推广应用工作管理办法专家研讨会在朝阳顺利举行",
          //   time: "2022-08-04",
          // },
        ];
        this.nowlistdata = aa;
        this.total = aa.length;
      } else if (num == 2) {
        let bb = [
          {
            id: 1,
            title: "点赞收藏_线上学习效率低？看朝阳专家团队来支招！",
            time: "2022-08-04",
          },
          {
            id: 2,
            title: "一图读懂 | 2022，朝阳教科院将这么干！",
            time: "2022-08-04",
          },
          {
            id: 3,
            title:
              "以教科研促学校高质量发展——科研专项调研走进北京市第94中朝阳新城分校",
            time: "2022-08-04",
          },
          {
            id: 4,
            title:
              "幼小衔接 我们在行动——朝阳区成功承办北京市幼小衔接阶段性教育成果经验交流会",
            time: "2022-08-04",
          },
          {
            id: 5,
            title:
              "扎根附中，为推进教学质量提升提供强大助力——初中教研部轮岗支教琐记",
            time: "2022-08-04",
          },
        ];
        this.nowlistdata = bb;
        this.total = bb.length;
      }
    },
    //
    gotopath(item) {
      console.log(this.nowtitle,'this.nowtitle,')
      this.$router.push({
        path: "/brandinfo",
        query: {
          id: item.id,
          text: item.title,
          title: this.nowtitle,
        },
      });
    },
    //分页
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagesize = val;
      this.querylist();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.curpage = val;
      this.querylist();
    },
  },
  watch: {
    $route: {
      handler(val, oldVal) {
        console.log(val, oldVal);
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.brand {
  .brand_center {
    width: 1200px;
    margin: 0 auto;
    header {
      padding: 15px 0;
    }
    main {
      display: flex;
      justify-content: space-between;
      .brand_left {
        width: 373px;
        max-height: 231px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #d2d2d2;
        .list_one {
          padding: 25px 30px;
          p {
            // width: 88px;
            // height: 29px;
            font-size: 22px;
            font-family: Microsoft YaHei-Bold, Microsoft YaHei;
            font-weight: bold;
            color: #141516;
            // line-height: 26px;
          }
        }
        .list_two,
        .list_three {
          cursor: pointer;
          padding: 25px 30px;
          p {
            // width: 120px;
            // height: 26px;
            font-size: 20px;
            font-family: Microsoft YaHei-Regular, Microsoft YaHei;
            font-weight: 400;
            // line-height: 23px;
          }
        }
        .blue {
          // width: 373px;
          // height: 72px;
          background: #1977f9;
          // border-radius: 0px 0px 0px 0px;
          // opacity: 1;
          p {
            color: #ffffff;
          }
        }
        .white {
          // width: 373px;
          // height: 72px;
          background: #ffffff;
          // border-radius: 0px 0px 0px 0px;
          // opacity: 1;
          p {
            color: #000;
          }
        }
      }
      .brand_right {
        width: 767px;
        // height: 453px;
        // border-radius: 0px 0px 0px 0px;
        // opacity: 1;
        .r_title {
          padding-bottom: 20px;
          // width: 132px;
          // height: 29px;
          font-size: 22px;
          font-family: Microsoft YaHei-Bold, Microsoft YaHei;
          font-weight: bold;
          color: #141516;
          line-height: 26px;
        }
        .r_list {
          min-height: 520px;
          padding: 25px 15px;
          border-top: 2px solid #1977f9;
          .liss {
            margin-bottom: 27px;
            display: flex;
            justify-content: space-between;
            .li_left {
              width: 629px;
              cursor: pointer;
              // height: 28px;
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #141516;
              // line-height: 21px;;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            .li_right {
              width: 101px;
              // height: 24px;
              font-size: 18px;
              font-family: Microsoft YaHei-Regular, Microsoft YaHei;
              font-weight: 400;
              color: #5d6066;
              // line-height: 21px;
            }
          }
        }
        .el-pagination {
          padding: 30px 0 0;
          border-top: 1px dotted #d2d2d2;
          display: flex;
          justify-content: center;
        }
      }
    }
  }
}
</style>